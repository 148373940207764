import { useState } from "react"
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TIMEOUT_MILLISECONDS, TIMEOUT_PROMPT_MILLISECONDS } from "../constants";

const TimeoutModal = ({ showModal, togglePopup, handleStayLoggedIn, handleExitSession }) => {

    const TIME_LEFT = (TIMEOUT_MILLISECONDS - TIMEOUT_PROMPT_MILLISECONDS) / 1000;
    const [timeLeft, setTimeLeft] = useState(TIME_LEFT);
    const [timer, setTimer] = useState()

    React.useEffect(() => {
        return () => {
            if(timer != null)
            {
                clearInterval(timer);
                setTimer(null);
            }
        };
    }, [timer]);

    const exiting = () => {
        clearInterval(timer);
        setTimer(null);
    };

    const onExitSession = () => {

        setTimeLeft(TIME_LEFT);
        clearInterval(timer);
        setTimer(null);
        handleExitSession();
    }

    React.useEffect(() => {
        if (timeLeft <= 0) {
            onExitSession();
        }
    // eslint-disable-next-line
    }, [timeLeft]);

    const intervalEvent = () => {
        setTimeLeft(c => c - 1)
    };

    const opening = () => {
        setTimeLeft(TIME_LEFT)
        var logoutTimer = setInterval(intervalEvent, 1000)
        setTimer(logoutTimer);
    };

    return (
        <Modal isOpen={showModal} toggle={togglePopup} keyboard={false} backdrop="static" onOpened = { opening } onClosed = { exiting } animation="false">
            <ModalHeader>Session Timeout!</ModalHeader>
            <ModalBody>
                Your session is about to expire in {timeLeft} seconds due to inactivity. The session will be reset.
            </ModalBody>
            <ModalFooter>
            <Button color="danger" onClick={onExitSession}>Exit Session</Button>
            <Button color="primary" onClick={handleStayLoggedIn}>Continue Session</Button>
            </ModalFooter>
        </Modal>
    )
}

export default TimeoutModal;
