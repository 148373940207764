import { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import Parse from "parse"
import useScript from "../misc/useScript"
import Loading from "../misc/Loading"
import Icon from "../misc/Icon"
import TermsOfService from "./TermsOfService"
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'
import TimeoutModal from "./TimeoutModal"
import { useHistory } from "react-router-dom"
import getLink from "../misc/routeUtils";
import useQuery from "../misc/useQuery";
import { TIMEOUT_MILLISECONDS, TIMEOUT_PROMPT_MILLISECONDS } from "../constants"

// Starting a new rental happens here. First, choose the gate, then the rate,
// then review the rental and checkout, and then show the confirmation.
export function NewRental({location}) {
  const {emailAddress, stripeCheckoutSessionId } = useParams()

  const [view, setView] = useState()
  const [gate, setGate] = useState()
  const [rates, setRates] = useState([])
  const [rate, setRate] = useState()
  const [locker, setLocker] = useState()
  const [pin, setPIN] = useState()
  const [showToS, setShowToS] = useState(false)

  const query = useQuery();

  useEffect(() => {
    const qGate = query.get("gate");
    setGate(qGate)
  }, [query])

  useEffect(() => {
    if(showToS){
      setView("showtermsofservice")
    }
    else
    if(stripeCheckoutSessionId) {
      setView("rentalconfirmation")
    }
    else if (!gate && location.gate) {
      setView("gates")
    }
    else if (!rate && location.variableRates) {
      if(location.bagCheck === true){
        setRates(location.variableRates.filter(rate => (!gate || rate.gate === gate) && rate.size === 'bag check'))
      }
      else{
        setRates(location.variableRates.filter(rate => (!gate || rate.gate === gate) && rate.size !== 'bag check'))
      }
      setView("rates")
    }
    else if(rate.price === "0" && (!pin || pin.length !== 4)){
      setView("pin")
    }
    else if(!stripeCheckoutSessionId){  // no stripecheckout id
      setView("checkout")
    }

  }, [location, gate, rate, pin, stripeCheckoutSessionId, showToS])

  return (
    <div>
    <h1 className="text-center h3">{window.$locationName}</h1>
    { gate && <h4 className="text-center">{gate} Gate</h4>}
    <div className="d-grid gap-3">
      {view === "gates" &&
        <ChooseGate
          gates={location.gate}
          onChoose={gate => setGate(gate)}
          location={location}
        />
      }
      {view === "rates" &&
        <ChooseRate
          location={location}
          emailAddress={emailAddress}
          gate={gate}
          rates={rates}
          onChoose={(rate, locker) => {
            setRate(rate)
            setLocker(locker)
          }}
        />
      }
      {view === 'pin' &&
        <ChoosePin onSubmit={pin => setPIN(pin)}/>
      }
      {view === "checkout" &&
        <Checkout
          location={location}
          emailAddress={emailAddress}
          gate={gate}
          rate={rate}
          locker={locker}
          pin={pin}
          onShowTermsOfService={ () => { setShowToS(true)} }
        />
      }
      { view === "rentalconfirmation" &&
          <RentalConfirmation location={location} pin={pin}/>
      }
      { view === "showtermsofservice" &&
        <TermsOfService onCloseTermsOfService={ () => { setShowToS(false) }}/>
      }
      {  /* this used to use /location/${location.objectId}/${emailAddress}` but going to put all the way back to the start */
      <Link to={getLink(`/location/${location.objectId}`, query)} className="btn text-muted d-flex align-items-center justify-content-center">
        <Icon icon="bi-arrow-left" className="me-2" />
        Start Over
      </Link>
      }
    </div>
    </div>
  )
}

// Prompts user to choose a location gate.
function ChooseGate({gates, onChoose, location}) {
  const [showModal, setShowModal] = useState(false)
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const onPrompt = () => {
    if (localStorage.getItem('isKiosk') === 'true')
      togglePopup();
    else
      setResetIdleTimer(true);
  }

  const onIdle = () => {
    pause();
    togglePopup();
    history.push(getLink(`/location/${location.objectId}`, query));
  }

  const {
    reset,
    pause,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT_MILLISECONDS,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])

  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    togglePopup();
    reset();
  }

  return (
    <div className="d-grid gap-4">
       <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
      <div className="text-center">Choose a gate location:</div>
      {gates.map(gate => (
        <button
          key={gate}
          type="button"
          className="btn btn-primary btn-lg rounded-pill px-4"
          onClick={() => onChoose(gate)}
        >
          {gate}
        </button>
      ))}
    </div>
  )
}

// Prompts user to choose a locker rate and fetch the locker from the backend.
function ChooseRate({location, emailAddress, gate, rates, onChoose}) {
  const [fetching, setFetching] = useState(false)
  const [unavailable, setUnavailable] = useState([])
  const history = useHistory();
  const [showModal, setShowModal] = useState(false)
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const query = useQuery()

  const onPrompt = () => {
    if (localStorage.getItem('isKiosk') === 'true')
      togglePopup();
    else
      setResetIdleTimer(true);
  }

  const onIdle = () => {
    pause();
    togglePopup();
    history.push(getLink(`/location/${location.objectId}`, query));
  }

  const {
    reset,
    pause,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT_MILLISECONDS,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])

  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    togglePopup();
    reset();
  }

  function getAvailableLocker(rate) {
    setFetching(rate.size)
    pause();
    Parse.Cloud.run("fetchAvailableLocker", {
      location: location.locationName,
      gate: gate,
      size: rate.size,
      user: emailAddress,
    })
    .then(locker => onChoose(rate, locker))
    .catch(() => {
      setFetching(false)
      reset()
      setUnavailable(unavailable => [...unavailable, rate.size])
    })
  }

  if(location.bagCheck === true) {

    return (
      <div className="d-grid gap-4">
       <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
       <div className="text-center">Choose:</div>
        {rates.map(rate => (
          <button
            key={rate.size}
            type="button"
            className={"btn w-100 rounded-pill py-2 px-4 d-flex align-items-center justify-content-between " + (unavailable.includes(rate.size) ? "btn-secondary" : "btn-primary")}
            disabled={fetching || unavailable.includes(rate.size)}
            onClick={() => getAvailableLocker(rate)}
          >
            <div className="text-start mr-3">
              <div className="h5 text-capitalize mb-0">{rate.size}</div>
              <div className="small">{rate.dimensions}</div>
            </div>
            {fetching !== rate.size && !unavailable.includes(rate.size) &&
              <div>${rate.price}/{rate.rate}</div>
            }
            {fetching === rate.size && !unavailable.includes(rate.size) &&
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            }
            {unavailable.includes(rate.size) &&
              <div>Unavailable</div>
            }
          </button>
        ))}
      </div>
    )
  }
  else
  {
    return (
      <div className="d-grid gap-4">
        <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
        <div className="text-center">Choose a locker size:</div>
        {rates.map(rate => (
          <button
            key={rate.size}
            type="button"
            className={"btn w-100 rounded-pill py-2 px-4 d-flex align-items-center justify-content-between " + (unavailable.includes(rate.size) ? "btn-secondary" : "btn-primary")}
            disabled={fetching || unavailable.includes(rate.size)}
            onClick={() => getAvailableLocker(rate)}
          >
            <div className="text-start mr-3">
              <div className="h5 text-capitalize mb-0">{rate.size}</div>
              <div className="small">{rate.dimensions}</div>
            </div>
            {fetching !== rate.size && !unavailable.includes(rate.size) &&
              <div>${rate.price}/{rate.rate}</div>
            }
            {fetching === rate.size && !unavailable.includes(rate.size) &&
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            }
            {unavailable.includes(rate.size) &&
              <div>Unavailable</div>
            }
          </button>
        ))}
      </div>
    )
  }
}

// Shows the rental details, prompts user to checkout, starts a Stripe Checkout
// session, and redirects user to Stripe Checkout.
function Checkout({location, emailAddress, gate, rate, locker, pin, onShowTermsOfService}) {
  const [checkingOut, setCheckingOut] = useState(false)
  const [error, setError] = useState()
  const scriptStatus = useScript("https://js.stripe.com/v3/")
  const [phoneNumber, setPhoneNumber] = useState()
  const [phoneError, setPhoneError] = useState(false)
  const [acceptedToS, setAcceptedToS] = useState(false)
  const history = useHistory();
  const [showModal, setShowModal] = useState(false)
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const query = useQuery();

  const divStyle = {
    color: 'red',
    fontSize: '10px',
  };

  const onPrompt = () => {
    if (localStorage.getItem('isKiosk') === 'true')
      togglePopup();
    else
      setResetIdleTimer(true);
  }

  const onIdle = () => {
    pause();
    togglePopup();
    history.push(getLink(`/location/${location.objectId}`, query));
  }

  const {
    reset,
    pause,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT_MILLISECONDS,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])

  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    togglePopup();
    reset();
  }

  function createCheckoutSession() {
    return Parse.Cloud.run("webAppStartStripeCheckout", {
        emailAddress: emailAddress,
        locationId: location.objectId,
        lockerId: locker.objectId,
        bagCheck: location.bagCheck,
        pin: pin,
        phoneNumber: phoneNumber,
        successURL: getLink(`${window.location.origin}/location/${location.objectId}/${emailAddress}/new-rental/{CHECKOUT_SESSION_ID}`, query),
        cancelURL: getLink(`${window.location.origin}/location/${location.objectId}/${emailAddress}`, query),
        test: process.env.REACT_APP_STRIPE_TEST === "true",
      })
      .then(stripeCheckoutSessionId => stripeCheckoutSessionId)
      .catch(() => Promise.reject("Sorry, we could not create your checkout session. Please contact customer service."))
  }

  function redirectToCheckout(stripeCheckoutSessionId) {
    if(stripeCheckoutSessionId.startsWith('free_')){
      // no stripe needed, but we are all setup on the backside, so just go right to confirmation
      window.location.href = getLink(`${window.location.origin}/location/${location.objectId}/${emailAddress}/new-rental/${stripeCheckoutSessionId}`, query)
    }
    else {
      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
      return stripe.redirectToCheckout({sessionId: stripeCheckoutSessionId})
      .catch(() => Promise.reject("Sorry, we could not redirect you to checkout. Please contact customer service."))
    }
  }

  function onAcceptToS(){
    setAcceptedToS(!acceptedToS)
  }

  function showToS(){
    onShowTermsOfService()
  }


  function checkout() {

    // validate the phone number ok if we get one
    if(phoneNumber && phoneNumber.length > 0){
      var rgx = new RegExp('^([0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10})$')
      if(!rgx.test(phoneNumber)){
        setPhoneError(true);
        return
      }
    }
    setPhoneError(false);

    setCheckingOut(true)

    // stop any further idle session timeouts
    pause();

    createCheckoutSession()
      .then(redirectToCheckout)
      .catch(error => {
        setCheckingOut(false)
        setError(error)
    })
  }

  const _price = parseInt(rate.price);
  const _fees = _price !== 0.00 ? Math.round((_price * .029 + .80) * 100) / 100 : 0.0;
  const _taxes = (location.taxRate && location.taxRate !== 0) ? Math.round((_price) * location.taxRate * 100) / 100 : 0;
  const _total = _price + _fees + _taxes;

  return (
    <>
      <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
      <ul className="list-group list-group shadow-sm">
        <li className="list-group-item d-flex justify-content-between align-items-center">
          Location:
          <div>{location.locationName}</div>
        </li>
        {gate &&
          <li className="list-group-item d-flex justify-content-between align-items-center">
            Gate:
            <div>{gate}</div>
          </li>
        }
        <li className="list-group-item d-flex justify-content-between align-items-center">
          Size:
          <div className="text-capitalize">{rate.size}</div>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center">
          Price:
          <div>${rate.price}/{rate.rate}</div>
        </li>
        { _fees > 0 &&
          <li className="list-group-item d-flex justify-content-between align-items-center">
            Fees:
            <div>${_fees.toFixed(2)}</div>
        </li>
        }
        { _taxes > 0 &&
          <li className="list-group-item d-flex justify-content-between align-items-center">
            Sales Tax:
            <div>${_taxes.toFixed(2)}</div>
          </li>
        }
        <li className="list-group-item d-flex justify-content-between align-items-center">
          Total:
          <div>${_total.toFixed(2)}</div>
        </li>
      </ul>
      <div className="d-grid">
        {/* <label className="form-label" htmlFor="phone">
                Enter your 10 digit cell phone number to receive a text message to easily return to your locker:
        </label>
        <input
          type="tel"
          id="phone"
          className="form-control"
          value={phoneNumber}
          onChange={event => setPhoneNumber(event.target.value)}
          autoFocus
          placeholder="999-999-9999" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        />
        {phoneError && <label style={divStyle}>Please enter a 10 digit phone number or leave empty to continue</label> }
        {scriptStatus !== "ready" &&
          <Loading />
        } */}

        {scriptStatus === "ready" &&
        <div>
          <div className="row mt-2">
          <label htmlFor="tos"><input type="checkbox" id="tos" name="tos" value="Accepted" onClick={onAcceptToS}/>
            &nbsp;I accept the Binbox <a href="/#" onClick={showToS}>Terms &amp; Conditions</a></label>
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="mt-4 btn btn-primary btn-lg rounded-pill px-4 d-flex align-items-center justify-content-center"
              onClick={checkout}
              disabled={checkingOut || error || acceptedToS === false}
            >
              Checkout
              {checkingOut &&
                <div className="spinner-border spinner-border-sm ms-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              }
            </button>
          </div>
        </div>
        }
        {error &&
          <div className="text-center text-danger">{error}</div>
        }
      </div>
    </>
  )
}

// Shows the rental confirmation details.
export function RentalConfirmation() {
  const {emailAddress, stripeCheckoutSessionId, locationId} = useParams()
  const [lockerNumber, setLockerNumber] = useState()
  const [accessCode, setAccessCode] = useState()
  const [rentalType, setRentalType] = useState()
  const [lockStatus, setLockStatus] = useState()
  const [locationName, setLocationName] = useState()
  const [bagCheck, setBagCheck] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const query = useQuery();
  const history = useHistory();

  const onPrompt = () => {
    if (localStorage.getItem('isKiosk') === 'true')
      togglePopup();
    else
      setResetIdleTimer(true);
  }

  const onIdle = () => {
    pause();
    togglePopup();
    history.push(getLink(`/location/${locationId}`, query));
  }

  const {
    reset,
    pause,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT_MILLISECONDS,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])
  
  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    togglePopup();
    reset();
  }

  function openWifiLocker(){
    Parse.Cloud.run("openWifiLocker", { lockerNum: lockerNumber, location: locationName})
      .then(opened => {
        setLockStatus("opened");
      })
      .catch(() => Promise.reject("The locker could not be opened.  Please contact customer service."))
  }

  useEffect(() => {
    Parse.Cloud.run("webAppFinishStripeCheckout", {
      stripeCheckoutSessionId: stripeCheckoutSessionId,
      test: process.env.REACT_APP_STRIPE_TEST === "test"
    })
    .then(({lockerNumber, accessCode, rentalType, locationName, bagCheck, locationId}) => {
      setLockerNumber(lockerNumber)
      setAccessCode(accessCode)
      setRentalType(rentalType)
      setLockStatus("locked")
      setLocationName(locationName)
      setBagCheck(bagCheck)
    })
    .catch(() => {
      setLockerNumber(false)
    })
  }, [stripeCheckoutSessionId])

  if (lockStatus === 'opened') {
    setTimeout(() => {
      history.push(getLink(`/location/${locationId}`, query));
    }, 5000)
    return <div className="d-grid gap-4">
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="text-center">Locker number <b>{lockerNumber}</b> is open</div>
        </div>
      </div>
    </div>
  } else if (bagCheck === true) {

    return lockerNumber === undefined ? <Loading /> : (
      <div className="d-grid gap-4">
        {lockerNumber !== false &&
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Success!</h5>
              <p>Your bag check number:</p>
              <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{lockerNumber}</p>
              <p>Please show this number to the attendant to tag your bag.</p>
            </div>
            { /*<div className="text-center"><a href={`/location/${location.objectId}/${emailAddress}/sendtext/${stripeCheckoutSessionId}`}>
                    Text me a link to open my locker</a></div>
              */
            }
          </div>
        }
        {lockerNumber === false &&
          <div className="text-center text-danger">Sorry, we could not complete your bag check. Please contact customer service.</div>
        }
        <Link to={getLink(`/location/${locationId}/${emailAddress}`, query)} className="btn btn-primary btn-lg mx-auto rounded-pill px-4">
          Okay!
        </Link>
      </div>
    )

  }
  else if (rentalType === 'wifi')
  {
    return lockerNumber === undefined ? <Loading /> : (
      <div className="d-grid gap-4">
        <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
        { lockerNumber !== false &&
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">Success!</h5>
                <p>Your locker number:</p>
                <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{lockerNumber}</p>
                <p>Locker State:</p>
                <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{lockStatus}</p>
                <div className="text-center">
                  {/* This line is redundant
                  <p>Tap here to open your locker</p>*/}
                  <button className="openbutton" onClick={openWifiLocker}>Tap to Open</button>
                </div>
                {/* Remove the note, just comment out in case we want to add some text here later
                <p className="mb-0"><span className="text-danger">Note:</span> this page only be used one time. After you close your locker,
                    you will not be able to open it until you end your rental.</p>

                <div className="text-center"><a href={`/location/${location.objectId}/${emailAddress}/sendtext/${stripeCheckoutSessionId}`}>
                      Text me a link to open my locker</a></div>
                */ }

              </div>
            </div>
          }
          {lockerNumber === false &&
            <div className="text-center text-danger">Sorry, we could not complete your rental. Please contact customer service.</div>
          }
          <Link to={getLink(`/location/${locationId}/${emailAddress}`, query)} className="btn btn-primary btn-lg mx-auto rounded-pill px-4">
            Okay!
          </Link>
      </div>
    )
  }
  else {
    return lockerNumber === undefined ? <Loading /> : (
      <div className="d-grid gap-4">
        {lockerNumber !== false &&
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Success!</h5>
              <p>Your locker number:</p>
              <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{lockerNumber}</p>
              <p>Your temporary access code:</p>
              <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{accessCode}</p>
              <p>Please use this temporary access code to open the locker and store your items.</p>
              {/*
              <p className="mb-0"><span className="text-danger">Note:</span> this code can only be used one time. After you close your locker,
                you will not be able to open it until you end your rental.</p>
              */}
            </div>
          </div>
        }
        {lockerNumber === false &&
          <div className="text-center text-danger">Sorry, we could not complete your rental. Please contact customer service.</div>
        }
        <Link to={getLink(`/location/${locationId}/${emailAddress}`, query)} className="btn btn-primary btn-lg mx-auto rounded-pill px-4">
          Okay!
        </Link>
      </div>
    )
  }
}

// Shows the rental confirmation details.
export function ChoosePin({onSubmit}) {
  const [pin, setPin] = useState()

  function validate(evt) {
    var theEvent = evt || window.event;
    var key;

    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
    // Handle key press
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  function handleSubmit(event) {
    event.preventDefault()
    onSubmit(pin)
  }

  return (
    <form className="d-grid gap-4 text-center" onSubmit={handleSubmit}>
      <label className="form-label" htmlFor="location-end-rental-enter-last-4">
        Enter a 4 digit PIN number that can be used to access your rental account.
      </label>
      <input
        id="location-end-rental-enter-last-4"
        className="form-control text-center"
        onKeyPress={validate}
        maxLength = "4"
        onChange={event => setPin(event.target.value)}
        autoFocus
        required
      />
      <button className="btn btn-primary" disabled={!pin || pin.length !== 4} type="submit">Continue</button>
    </form>
  )

}

