import { useState, useEffect } from "react"
import {Switch, Route, Link, useHistory, useParams, useRouteMatch} from "react-router-dom"
import Parse from "parse"
import { NewRental, RentalConfirmation } from "./NewRental"
import { EndRental } from "./EndRental"
import Loading from "../misc/Loading"
import Icon from "../misc/Icon"
import { TermsOfService } from "./TermsOfService"

import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'
import TimeoutModal from "./TimeoutModal"
import getLink from "../misc/routeUtils";
import useQuery from "../misc/useQuery";
import { TIMEOUT_MILLISECONDS, TIMEOUT_PROMPT_MILLISECONDS } from "../constants"

// Top-level router for Location section of app. Helps to ensure that we know
// the location ID and the user's email address.
function Location() {

  return (
    <Switch>
      <Route path="termsofservice">
        <TermsOfService />
      </Route>
      <Route path="/location/:locationId/:emailAddress/new-rental/:stripeCheckoutSessionId">
        <RentalConfirmation/>
      </Route>
      <Route path="/location/:locationId/:emailAddress">
        <Rental />
      </Route>
      <Route path="/location/:locationId">
        <EnterEmailAddress />
      </Route>
      <Route path="/location">
        <ChooseLocation />
      </Route>
    </Switch>
  )
}


// Prompts user to select a location.
function ChooseLocation({onChoose}) {
  const [locations, setLocations] = useState()

  const loadLocations = () => {
    setLocations(undefined)
    Parse.Cloud.run("webAppListLocations")
      .then(locations => setLocations(locations))
      .catch(() => { setLocations(false) })
  }

  useEffect(() => {
    loadLocations()
  }, [])

  if (locations === undefined) {
    return <Loading />
  }

  else if (locations === false || !locations.length) {
    return <div className="text-center">
      <div className="text-center text-danger mb-4">Sorry, we could not load any locations. Please contact customer service.</div>
      <a
        className="btn btn-primary btn-lg rounded-pill px-4"
        href={`/#`}
        onClick={() => {loadLocations()}}
      >
        Start Over
      </a>
    </div>
  }

  locations.sort((a, b) => (a.locationName.toUpperCase() > b.locationName.toUpperCase()) ? 1 : -1)
  return (
    <div className="d-grid gap-4">
      <div className="text-center">Choose a location:</div>
      {locations.map(location => (
        <a
          key={location.objectId}
          className="btn btn-primary btn-lg rounded-pill px-4"
          href={`/location/${location.objectId}?name=${location.locationName.replace('&amp;', '%26')}`}
        >
          {location.locationName.replace('&amp;', '&')}
        </a>
      ))}
    </div>
  )
}

// Prompts user to enter their email address.
function EnterEmailAddress() {
  const history = useHistory()
  const {locationId} = useParams()
  const [emailAddress, setEmailAddress] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [locationDisplay, setLocationName] = useState();
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const query = useQuery();

  const onIdle = () => {
    // clear the email address so far
    setEmailAddress("")
    setResetIdleTimer(true);
    setShowModal(false);
  }

  const onPrompt = () => {
    if (emailAddress != null && emailAddress.length > 0 && localStorage.getItem('isKiosk') === 'true') {
        setShowModal(true);
    } else{
      setResetIdleTimer(true);
    }
  }

  const {
    reset,
    pause,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT_MILLISECONDS,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])  

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let locationName = params.get('name');
    if (locationName && locationName.length > 0)
    {
      window.$locationName = locationName;
      localStorage.setItem('locationName', locationName);
    }
    else if(!window.$locationName || window.$locationName.length === 0)
    {
      window.$locationName = localStorage.getItem('locationName')
      locationName = localStorage.getItem('locationName')
    }
    else
    {
      locationName = window.$locationName
    }
    setLocationName(locationName)

    if (params.get('kiosk')) {
      const kiosk = params.get('kiosk').toLowerCase() === 'true';
      localStorage.setItem('isKiosk', kiosk);
    } 

    if (localStorage.getItem('isKiosk') !== 'true' && localStorage.getItem('email')) {
      setEmailAddress(localStorage.getItem('email'));
      history.push(getLink(`/location/${locationId}/${emailAddress}`, query))
    }
  }, [locationDisplay]);

  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    reset();
    setShowModal(false);
  }

  function handleSubmit(event) {
    event.preventDefault()
    pause();
    localStorage.setItem('email', emailAddress);
    history.push(getLink(`/location/${locationId}/${emailAddress}`, query))
  }


  return (
    <form className="d-grid gap-2 text-center" onSubmit={handleSubmit}>
      <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
      <h1 className="text-center h3">{locationDisplay}</h1>
      <label className="form-label" htmlFor="location-enter-email-address">
        Enter your email address:
      </label>
      <input
        type="email"
        id="location-enter-email-address"
        className="form-control text-center"
        value={emailAddress}
        onChange={event => setEmailAddress(event.target.value)}
        autoFocus
        required
      />
      <button className="btn btn-primary rounded-pill" type="submit">Submit</button>
    </form>
  )

}

// Finally, we know the location ID and the user's email address. Load the
// location and show buttons to start or end a rental.
function Rental() {
  const history = useHistory();
  const {locationId} = useParams()
  const {path, url} = useRouteMatch()
  const [location, setLocation] = useState()
  const [showModal, setShowModal] = useState(false)
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const query = useQuery();

  const onPrompt = () => {
    if (localStorage.getItem('isKiosk') === 'true')
      setShowModal(true);
    else
      setResetIdleTimer(true);
  }

  const onIdle = () => {
    pause();
    setShowModal(false);
    history.push(getLink(`/location/${location.objectId}`, query));
  }

  const {
    reset,
    pause,
    resume,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT_MILLISECONDS,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])

  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    setShowModal(false);
    reset();
  }

  useEffect(() => {
    let index = path.indexOf('/new-rental/')
    if(index > 0 && path.length > index)
    {
      pause()
    }
    else
    {
      Parse.Cloud.run("webAppListLocations")
      .then(locations => {
        setLocation(locations.find(location => location.objectId === locationId));
        reset();
        resume();
        if(!window.$locationName)
          window.$locationName = localStorage.getItem('locationName')
      })
      .catch(() => setLocation(false))
    }
  }, [locationId, reset, resume, path, url, pause ])

  if (location === undefined) {
    return <Loading />
  }

  else if (location === false) {
    return <div className="text-center text-danger">Sorry, we could not load that location. Please contact customer service.
        <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
    </div>
  }

  else if (location.bagCheck === true) {

    return (
      <>
        <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
        <Switch>
          <Route path="termsofservice">
            <TermsOfService />
          </Route>
          <Route path={`${path}/new-rental/:stripeCheckoutSessionId`}>
            <RentalConfirmation location={location} />
          </Route>
          <Route path={`${path}/new-rental`}>
            <NewRental location={location} />
          </Route>
          <Route path={`${path}/end-rental`}>
            <EndRental location={location} />
          </Route>
          <Route path={path}>
            <div className="d-grid gap-4">
            <h1 className="text-center h3">{window.$locationName}</h1>
              <Link to={getLink(`${url}/new-rental`, query)} onClick={() => pause()} className="btn btn-primary btn-lg rounded-pill px-4 d-flex align-items-center justify-content-between py-3">
                Check a Bag
                <Icon icon="bi-box-arrow-in-right" className="ms-2" />
              </Link>
              <Link to={getLink(`${url}/end-rental`, query)} onClick={() => pause()} className="btn btn-primary btn-lg rounded-pill px-4 d-flex align-items-center justify-content-between py-3">
                Retrieve Your Items
                <Icon icon="bi-box-arrow-right" className="ms-2" />
              </Link>
              {  /* this used to use /location/${location.objectId}/${emailAddress}` but going to put all the way back to the start */
                <Link to={getLink(`/location/${location.objectId}`, query)} className="btn text-muted d-flex align-items-center justify-content-center">
                <Icon icon="bi-arrow-left" className="me-2" />
                  Start Over
                </Link>
              }
            </div>
          </Route>
        </Switch>
      </>
    )
  }

  return (
    <>
      { /* <h1 className="">{window.$locationName}</h1> */ }
      <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
      <Switch>
        <Route path="termsofservice">
          <TermsOfService />
        </Route>
        <Route path={`${path}/new-rental/:stripeCheckoutSessionId`}>
          <RentalConfirmation location={location} />
        </Route>
        <Route path={`${path}/new-rental`}>
          <NewRental location={location} />
        </Route>
        <Route path={`${path}/end-rental`}>
          <EndRental location={location} />
        </Route>
        <Route path={`${path}/termsofservice`}>
          <TermsOfService/>
        </Route>
        <Route path={path}>
          <div className="d-grid gap-4">
          <h1 className="text-center h3">{window.$locationName}</h1>
            <Link to={getLink(`${url}/new-rental`, query)} onClick={() => pause()} className="btn btn-primary btn-lg rounded-pill px-4 d-flex align-items-center justify-content-between py-3">
              Rent a Locker
              <Icon icon="bi-box-arrow-in-right" className="ms-2" />
            </Link>
            <Link to={getLink(`${url}/end-rental`, query)} onClick={() => pause()} className="btn btn-primary btn-lg rounded-pill px-4 d-flex align-items-center justify-content-between py-3">
              Retrieve Your Items
              <Icon icon="bi-box-arrow-right" className="ms-2" />
            </Link>
            {  /* this used to use /location/${location.objectId}/${emailAddress}` but going to put all the way back to the start */
              <Link to={getLink(`/location/${location.objectId}`, query)} className="btn text-muted d-flex align-items-center justify-content-center">
              <Icon icon="bi-arrow-left" className="me-2" />
                Start Over
              </Link>
            }
          </div>
        </Route>
      </Switch>
    </>
  )
}

export default Location
