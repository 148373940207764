import { Switch, Route, Redirect } from "react-router-dom"
import Parse from "parse"
import Location from "./location/Location"
import "./styles.scss"
import TermsOfService from "./location/TermsOfService";

// Configure parse.
// Production
//Parse.serverURL = "https://binbox.back4app.io"
//Parse.initialize("JLOFCKtd9xQmtJsxMNoVYRz99bbPy0S7B3f5ZZBJ", "npekDAXxHFkAYfbjtsvo4DtJDOnjSWeQ41mGxalj")
// Beta (BinboxTesting)
//Parse.serverURL = 'https://parseapi.back4app.com'
//Parse.initialize("bF3qycdaqNy4BtN0C6ja39GFOkX2jqZQ18F8iImG", "TkNFojeK3t00DJeQI6ZWhZ9XdRz2q9bvzYTpoI4B")
// Local, DONT CHECK-IN with this enabled
//Parse.serverURL = 'http://localhost:1337/parse'
//Parse.initialize("testing", "testing")
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL;
Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_CLIENT_ID)

window.addEventListener('load', e => {
  registerSW(); 
});



async function registerSW() { 
  if ('serviceWorker' in navigator) { 
    try {
      await navigator.serviceWorker.register('/serviceworker.js'); 
    } catch (e) {
      alert('ServiceWorker registration failed. Sorry about that. ' + e); 
    }
  } else {
    //document.querySelector('.alert').removeAttribute('hidden');
  }
}


// Top-level router for application. At this point, it simply redirects users
// to the /location URL.
export default function App() {

  return (
    <Switch>
      <Route path="/termsofservice">
        <TermsOfService/>
      </Route>
      <Route path="/location">
        <Location />
      </Route>
      <Route path="/">
        <Redirect to="/location" />
      </Route>
    </Switch>
  )
}
