import { useParams } from "react-router-dom"
import { useEffect } from "react"

// show a term of service page
export function TermsOfService(onCloseTermsOfService) {
  const {emailAddress } = useParams()

  useEffect(() => {
    console.log("Terms of service page loading")
  })

  function backtoCheckout() {
    console.log("Close TOS button clicked")
    onCloseTermsOfService.onCloseTermsOfService()
  }

  function emailTOS() {
    console.log("email TOS to " + emailAddress)
  }


  return (

    <div>
        <div><h2>Terms &amp; Conditions</h2></div>

        <p><b>1. General.</b></p>
            <p>(a) The software (including any embedded software and third party software), documentation, interfaces, content, fonts and any data that came with this application, as may be updated or replaced by feature enhancements, or software updates (collectively “Software”) provided by Binbox™ whether in read only memory, on any other media or in any other form are licensed, not sold, to you by Binbox ™ for use only under the terms of this License. Binbox ™ retain sole ownership of the Software and reserve all rights not expressly granted to you. You agree that, by installing and using this app, that the terms of this License will apply to the Binbox ™ app.</p>
            <p>(b) Binbox ™, including any third party or vendor of its choosing may make available future Software Updates to improve the experience of using this app. The updates, if any, may not necessarily include all existing software features or new features for effective use on mobile devices of any generation. The terms of this License will govern any Software Updates that replace and/or supplement the original Software product.</p>
            <p>(c) Subject to the terms and conditions of this License, you are granted a limited non-exclusive license to use the app to use and obtain services from Binbox ™ and its innovative locker storage system. Unless as provided in a separate agreement between you and Binbox ™, this License does not allow the Binbox ™ app or its Software, in any form, to exist on more than one than one mobile device at one time and you may not distribute or make the Binbox ™ app or its software available over a network where it could be used by multiple devices at the same time. This License does not grant you any rights to use Binbox ™ proprietary interfaces and other intellectual property in the design, development, manufacture, licensing or distribution of third party devices and accessories, or third party software applications, in any form.</p>
            <p>(d) You may not, and you agree not to or enable others to copy (except as expressly permitted by this License), decompile, reverse engineer, disassemble, attempt to derive the source code of, decrypt, modify, or create derivative works of the Binbox ™ Software or any services in any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law or by licensing terms governing use of open-source components).</p>
            <p>(e) You agree to use the Binbox ™ app and the Services (as defined in Section 2 below) in compliance with all applicable laws, including local laws of the country or region in which you reside or in which you download or use the Binbox ™ Software and Services. A Wi-Fi or cellular data connection may be required for use the Binbox ™ app and to access its services.</p>
        <p><b>2. Services and Third Party Materials.</b></p>
            <p>(a) The Binbox ™ app and its Software may enable access to other applications on your mobile device and other third party services and web sites (collectively and individually, “Services”). Use of these Services may require Internet access and use of certain Services may require you to accept additional terms and may be subject to additional fees.</p>
            <p>(b) Certain Services may display, include or make available content, data, information, applications or materials from third parties (“Third Party Materials”) or provide links to certain third party web sites. By using the Services, you acknowledge and agree that Binbox ™ is not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third Party Materials or web sites. Binbox ™, its officers, and licensors do not warrant or endorse and do not assume and will not have any liability or responsibility to you or any other person for any Third Party Services, Third Party Materials or web sites, or for any other materials, products, or services of third parties. Third Party Materials and links to other web sites are provided solely as a convenience to you.</p>
            <p>(c) In addition, Services and Third Party Materials that may be accessed, linked to or displayed on your mobile device may not available in all languages or in all countries or regions. Binbox ™ makes
                   no representation that such Services and Third Party Materials are appropriate or available for use in any particular location. To the extent you choose to use or access such Services and Third Party Materials, you do so at your own initiative and are responsible for compliance with any applicable laws, including but not limited to applicable local laws and privacy and data collection laws. Binbox ™ and its licensors reserve the right to change, suspend, remove, or disable access to any Services at any time without notice. In no event will Binbox ™ be liable for the removal of or disabling of access to any such Services. Binbox ™ may also impose limits on the use of or access to certain Services, in any case and without notice or liability.</p>

        <p><b>3. Termination.</b></p>
            <p>This License is effective until terminated. Your rights under this License will terminate automatically or otherwise cease to be effective without notice from Binbox ™ if you fail to comply with any term(s) of this License. Upon the termination of this License, you shall cease all use of the Binbox App iOS Software. Sections 4, 5, 6, 7, and 8, of this License shall survive any such termination.</p>
        <p><b>4. Disclaimer of Warranties.</b></p>
            <p>(a) BY USING THE Binbox ™ APP OR ACCESSING ITS SERVICES, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, USE OF THE Binbox™ SOFTWARE AND ANY SERVICES PERFORMED BY OR ACCESSED THROUGH THE Binbox™ APP OR ITS SOFTWARE IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU.</p>
            <p>(b) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE Binbox ™ SOFTWARE AND SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND Binbox™ AND ITS LICENSORS (COLLECTIVELY REFERRED TO AS “Binbox ™” FOR THE PURPOSES OF SECTIONS 7 AND 8) HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE ITS SOFTWARE AND SERVICES, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, QUIET ENJOYMENT, AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS.</p>
            <p>(c) Binbox ™ DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE ITS SOFTWARE AND SERVICES, THAT THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR PROVIDED BY, THE Binbox ™ SOFTWARE WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE Binbox™ SOFTWARE AND SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY SERVICE WILL CONTINUE TO BE MADE AVAILABLE, THAT DEFECTS IN THE Binbox™ SOFTWARE OR SERVICES WILL BE CORRECTED, OR THAT THE Binbox ™ SOFTWARE WILL BE COMPATIBLE OR WORK WITH ANY MOBILE DEVICE, APPLICATIONS OR THIRD PARTY SERVICES. INSTALLATION OF THIS Binbox ™ SOFTWARE MAY AFFECT THE AVAILABILITY AND USABILITY OF A MOBILE DEVICE, THIRD PARTY APPLICATIONS OR THIRD PARTY SERVICES.</p>
            <p>(d) YOU FURTHER ACKNOWLEDGE THAT THE Binbox ™ SOFTWARE AND SERVICES ARE NOT INTENDED OR SUITABLE FOR USE IN SITUATIONS OR ENVIRONMENTS WHERE THE FAILURE OR TIME DELAYS OF, OR ERRORS OR INACCURACIES IN, THE CONTENT, DATA OR INFORMATION PROVIDED BY THE Binbox ™ SOFTWARE OR SERVICES COULD LEAD TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR ENVIRONMENTAL DAMAGE.</p>
            <p>(e) NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY Binbox ™ OR A Binbox ™ REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE Binbox ™ SOFTWARE OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION TO YOUR MOBILE DEVICE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</p>

        <p><b>5. Limitation of Liability</b></p>
            <p>TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT SHALL Binbox ™, ITS AFFILIATES, AGENTS, OR PRINCIPALS BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, CORRUPTION OR LOSS OF DATA, FAILURE TO TRANSMIT OR RECEIVE ANY DATA (INCLUDING WITHOUT LIMITATION COURSE INSTRUCTIONS, ASSIGNMENTS AND MATERIALS), BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE Binbox ™ SOFTWARE AND SERVICES, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF Binbox ™ HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. In no event shall Apple’s total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars (U.S. $50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.</p>


        <p><b>6. Digital Certificates.</b></p>

            <p>The Binbox™ Software contains functionality that allows it to accept digital certificates. YOU ARE SOLELY RESPONSIBLE FOR DECIDING WHETHER OR NOT TO RELY ON A CERTIFICATE WHETHER ISSUED BY Binbox ™. YOUR USE OF DIGITAL CERTIFICATES IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Binbox ™ MAKES NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, AS TO MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, ACCURACY, SECURITY, OR NONINFRINGEMENT OF WITH RESPECT TO Binbox ™ DIGITAL CERTIFICATES.</p>


        <p><b>7. Controlling Law and Severability.</b></p>

            <p>This License will be governed by and construed in accordance with the laws of the State of Virginia, excluding its conflict of law principles. Any litigation relating to this Agreement shall be pursued in the State of Virginia, Arlington County.</p>

            <p>This License shall not be governed by the United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded. If for any reason a court of competent jurisdiction finds any provision, or portion thereof, to be unenforceable, the remainder of this License shall continue in full force and effect.</p>


            <p><b>8. Complete Agreement; Governing Language.</b></p>

            <p>This License constitutes the entire agreement between you and Binbox ™ and supersedes all prior or contemporaneous understandings regarding such subject matter. No amendment to, or modification of, this License will be binding unless in writing and signed by Binbox ™.</p>

            <p>NOTICES FROM APPLE If Binbox ™ needs to contact you about your account, you consent to receive the notices by email. You agree that any such notices that we send you electronically will satisfy any legal communication requirements.</p>

        <div className="mt-3">
            <button type="button" className="mr-1 btn btn-primary rounded-pill" onClick={backtoCheckout}>
                Return
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button type="button" className="ml-1 btn btn-primary rounded-pill" onClick={emailTOS}>
                Email
            </button>
        </div>
   </div>
  )
}

export default TermsOfService
