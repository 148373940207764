import { useState, useEffect } from "react"
import {Link, useParams, useHistory} from "react-router-dom"
import Parse from "parse"
import Loading from "../misc/Loading"
import Icon from "../misc/Icon"
import TimeoutModal from "./TimeoutModal"
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'
import useQuery from "../misc/useQuery";
import getLink from "../misc/routeUtils";
import { TIMEOUT_MILLISECONDS, TIMEOUT_PROMPT_MILLISECONDS } from "../constants"

// Ending a rental happens here. First, enter the last 4 digits, then select
// the rental, then end it.
export function EndRental({location}) {
  const {emailAddress} = useParams()
  const [view, setView] = useState()
  const [last4, setLast4] = useState()
  const [rental, setRental] = useState()
  const [endrental, setEndRental] = useState(false)
  const query = useQuery();

  useEffect(() => {
    if (!last4) {
      setView("last4")
    }
    else if (!rental) {
      setView("rental")
    }
    else if(endrental === true)
    {
      setView("confirmendrental")
    }
    else{
      setView("confirmation")
    }
  }, [last4, rental, endrental])

  return (
    <div className="d-grid gap-5">
      {view === "last4" &&
        <EnterLast4 onSubmit={last4 => setLast4(last4)} location={location} />
      }
      {view === "rental" &&
        <ChooseRental
          emailAddress={emailAddress}
          last4={last4}
          location={location.locationName}
          onChoose={rental => setRental(rental)}
          onTryAgain={ () => { setLast4(null)} }
        />
      }
      {view === "confirmation" &&
        <Confirmation
          location={location}
          emailAddress={emailAddress}
          rental={rental}
          onEndRental={ () => {
              setEndRental(true)
            }
          }
        />
      }
      {view === "confirmendrental" &&
        <ConfirmEndRental
          location={location}
          locationId={location.objectId}
          emailAddress={emailAddress}
          rental={rental}
          onConfirmEnd={ ended =>
            setEndRental(ended)
          }
        />
      }
      {view !== "confirmation" &&
        <Link to={getLink(`/location/${location.objectId}`, query)} className="btn text-muted d-flex align-items-center justify-content-center">
          <Icon icon="bi-arrow-left" className="me-2" />
          Start Over
        </Link>
      }
    </div>
  )
}

// Prompts user to enter the last 4 digits of their credit card.
function EnterLast4({onSubmit, location}) {
  const [last4, setLast4] = useState("")
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const query = useQuery()

  const onPrompt = () => {
    if (localStorage.getItem('isKiosk') === 'true')
      togglePopup();
    else
      setResetIdleTimer(true);
  }

  const onIdle = () => {
    pause();
    togglePopup();
    history.push(getLink(`/location/${location.objectId}`, query));
  }

  const {
    reset,
    pause,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT_MILLISECONDS,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])

  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    togglePopup();
    reset();
  }

  function handleSubmit(event) {
    event.preventDefault()
    onSubmit(last4)
  }

  function validate(evt) {
    var theEvent = evt || window.event;
    var key;

    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
    // Handle key press
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  return (
    <form className="d-grid gap-4 text-center" onSubmit={handleSubmit}>
      <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
      <h1 className="text-center h3">{window.$locationName}</h1>
      <label className="form-label" htmlFor="location-end-rental-enter-last-4">
        Enter the last 4 digits of the credit card used to rent your locker or your self selected 4 digit PIN number:
      </label>
      <input
        id="location-end-rental-enter-last-4"
        className="form-control text-center"
        onKeyPress={validate}
        value={last4}
        maxLength = "4"
        onChange={event => setLast4(event.target.value)}
        autoFocus
        required
      />
      <button className="btn btn-primary" type="submit">Submit</button>
    </form>
  )
}

// Prompts user to choose from their available rentals.
function ChooseRental({emailAddress, last4, location, onChoose, onTryAgain}) {
  const [rentals, setRentals] = useState()
  const [showModal, setShowModal] = useState(false)
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const onPrompt = () => {
    if (localStorage.getItem('isKiosk') === 'true')
      togglePopup();
    else
      setResetIdleTimer(true);
  }

  const onIdle = () => {
    pause();
    togglePopup();
    history.push(getLink(`/location/${location.objectId}`, query));
  }

  const {
    reset,
    pause,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT_MILLISECONDS,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])

  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    togglePopup();
    reset();
  }

  useEffect(() => {
    Parse.Cloud.run("webAppListUserRentals", {
      emailAddress: emailAddress,
      last4: last4,
      location:location
    })
    .then(rentals => {
      if (rentals.length === 1) {
        onChoose(rentals[0])
      }
      else {
        setRentals(rentals)
      }
    })
    .catch(() => setRentals(false))
  }, [location, emailAddress, last4, onChoose])

  if (rentals === undefined) {
    return <Loading />
  }

  else if (rentals === false) {
    return (
    <>
    <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
    <div className="text-center text-danger">Sorry, we could not locate your rentals. Please contact customer service.</div>
    </>
    )
  }

  else if (!rentals.length) {
    return (
      <>
        <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
        <div className="d-grid gap-4">
          <div className="text-center text-danger">You don't have any open locker rentals.</div>
          <button
            type="button"
            className="btn btn-primary btn-lg rounded-pill px-4"
            onClick={() => onTryAgain() }
          >
          Try Again
          </button>
        </div>
      </>
    )
  }

  return (
    <div className="d-grid gap-4">
      <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
      <div className="text-center">Choose a rental:</div>
      {rentals.map(rental => (
        <button
          key={rental.objectId}
          type="button"
          className="btn btn-primary btn-lg rounded-pill px-4"
          onClick={() => onChoose(rental)}
        >
          Locker #{rental.lockerNumber}
        </button>
      ))}
    </div>
  )
}

// verify the user really wants to end their rental, once they go past this, there is
// no going back, and the locker is free to renta again
export function ConfirmEndRental({rental, locationId, emailAddress, onConfirmEnd, location}){
  const history = useHistory()
  const [error, setError] = useState()
  const [showModal, setShowModal] = useState(false)
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const query = useQuery()

  const onPrompt = () => {
    if (localStorage.getItem('isKiosk') === 'true')
      togglePopup();
    else
      setResetIdleTimer(true);
  }

  const onIdle = () => {
    pause();
    togglePopup();
    history.push(getLink(`/location/${location.objectId}`, query));
  }

  const {
    reset,
    pause,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT_MILLISECONDS,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])

  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    togglePopup();
    reset();
  }


  function endRental(){
    Parse.Cloud.run("webAppEndRental", { webAppUserId: rental.objectId })
    .then(locker => {
      history.push(getLink(`/location/${locationId}`, query), { replace: true });
    })
    .catch(e => setError(e))
  }

  return (
    <div className="d-grid gap-4">
      <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
      <div className="text-center">Pressing Continue will end your Rental for <b>Locker&nbsp;{rental.lockerNumber}</b> and confirm the locker as avaiable to other users.</div>
      <div className="text-center">Are you sure you want to Continue and end your rental?</div>
      <button
          type="button"
          className="btn btn-danger btn-lg rounded-pill px-4"
          onClick={ () => endRental()}>
        Continue
      </button>
      <button
          type="button"
          className="btn btn-primary btn-lg rounded-pill px-4"
          onClick={() => onConfirmEnd(false)}>
        Go Back
      </button>
      {error &&
          <div className="text-center text-danger">There was an error while ending the rental, please contact Customer Server.</div>
      }
    </div>
  );
}


// Shows the rental confirmation details.
function Confirmation({location, emailAddress, rental, onEndRental}) {
  const [lockStatus, setLockStatus] = useState("locked")
  const [error, setError] = useState()
  const [accessCode, setAccessCode] = useState()
  const [lockerNumber, setLockerNumber] = useState()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [resetIdleTimer, setResetIdleTimer] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const onPrompt = () => {
    if (localStorage.getItem('isKiosk') === 'true')
      togglePopup();
    else
      setResetIdleTimer(true);
  }

  const onIdle = () => {
    pause();
    togglePopup();
    history.push(getLink(`/location/${location.objectId}`, query));
  }

  const {
    reset,
    pause,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 60000,
    promptBeforeIdle: TIMEOUT_PROMPT_MILLISECONDS,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: true,
    crossTab: false,
    syncTimers: 0
  })

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      setResetIdleTimer(false);
    }
  }, [resetIdleTimer, reset])

  function togglePopup(_)
  {
    setShowModal(!showModal)
  }

  function handleStayLoggedIn()
  {
    // hide the popup and reset the timer
    togglePopup();
    reset();
  }

  // this is duplicated from NewRental.RentalConfirmation, need to create a place for common methods
  function openWifiLocker(){

    Parse.Cloud.run("openWifiLocker", { lockerNum: rental.lockerNumber, location: location.locationName})
      .then(opened => {
        setLockStatus("opened");
        setError(null)
      })
      .catch((error) => setError("The locker could not be opened.  Please contact customer service."))
  }

  function onClickEndRental(){
    onEndRental();
  }


  useEffect(() => {

    if(rental.rentalType !== 'wifi'){
      if(!accessCode && !lockerNumber && loading === false){
        async function updateAccessCode() {
          try {
            var lockerinfo = await Parse.Cloud.run("updateBluetoothCode", { webAppUserId: rental.objectId });
            setAccessCode(lockerinfo.accessCode)
            setLockerNumber(lockerinfo.lockerNumber)
          } catch(error) {
            setError("The locker rental was not found.  Please contact customer service.")
          }
        };
        updateAccessCode();
        setLoading(true)
      }
    }
    else
    {
      setLockerNumber(rental.lockerNumber)
    }

  },[location, accessCode, lockerNumber, rental, loading])

  if (lockStatus === 'opened') {
    setTimeout(() => {
      history.push(getLink(`/location/${location.objectId}`, query));
    }, 5000)
    return <div className="d-grid gap-4">
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="text-center">Locker number <b>{lockerNumber}</b> is open</div>
        </div>
      </div>
    </div>
  } else if (error){
    return (
      <div className="d-grid gap-4">
        <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
        <div className="card shadow-sm">
          <div className="card-body">
          <div className="text-center text-danger">Error closing out locker rental.</div>
        </div>
      </div>
      <Link to={getLink(`/location/${location.objectId}/${emailAddress}`, query)} className="btn btn-primary btn-lg mx-auto rounded-pill px-4">
        Okay!
      </Link>
    </div>
   )
  }
  else  if(!accessCode && !lockerNumber && rental.rentalType !== 'wifi'){
    return <Loading />
  }
  else if (location.bagCheck === true) {
    return (
      <div className="d-grid gap-4">
        <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
        <div className="card shadow-sm">
          <div className="card-body">
            <p>Your bag check number:</p>
            <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{rental.lockerNumber}</p>
            <p>Please show this number to the attendant to retrieve your items.</p>
          </div>
        </div>
        {error &&
            <div className="text-center text-danger">{error}</div>
        }
        <Link to={getLink(`/location/${location.objectId}/${emailAddress}`, query)} className="btn btn-primary btn-lg mx-auto rounded-pill px-4">
          Okay!
        </Link>
      </div>
    )
  }
  else if(rental.rentalType === 'wifi'){
    return (
      <div className="d-grid gap-4">
        <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
        <div className="card shadow-sm">
          <div className="card-body">
            <p>Your locker number:</p>
            <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{lockerNumber}</p>
            <p>Locker State:</p>
            <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{lockStatus}</p>
            <div className="text-center">
              { /* I think this message is redundant
              <p>Tap here to open your locker</p>
              */
              }
              <button className="openbutton" onClick={openWifiLocker}>Tap to Open</button>
            </div>
            {/* Remove the note, just comment out in case we want to add some text here later
            <p className="mb-0"><span className="text-danger">Note:</span> this page only be used one time. After you close your locker,
                you will not be able to open it until you end your rental.</p>
            */
              // <div className="text-center">
              //   <a href="/#" onClick={onClickEndRental}>End my rental</a>
              // </div>
            }
          </div>
        </div>
        {error &&
          <div className="text-center text-danger">{error}</div>
        }
        <Link to={getLink(`/location/${location.objectId}/${emailAddress}`, query)} className="btn btn-primary btn-lg mx-auto rounded-pill px-4">
          Okay!
        </Link>
      </div>
    )
  }
  else {  // bluetooth locker
    return (
      <div className="d-grid gap-4">
        <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} handleExitSession={onIdle}/>
        <div className="card shadow-sm">
          <div className="card-body">
            <p>Your locker number:</p>
            <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{lockerNumber}</p>
            <p>Your temporary access code:</p>
            <p className="border border-secondary border-2 rounded bg-light py-2 text-center font-monospace fs-4">{accessCode}</p>
            <p>Please use this temporary access code to open the locker and retrieve your items.</p>
            {/*<p className="mb-0"><span className="text-danger">Note:</span> this code can only be used one time.
              After you close your locker, you will not be able to open it again.</p>
              */
            }
            {/* <div className="text-center">
              <a href="/#" onClick={onClickEndRental}>End my rental</a>
            </div> */}
          </div>
        </div>
        {error &&
            <div className="text-center text-danger">{error}</div>
        }
        <Link to={getLink(`/location/${location.objectId}/${emailAddress}`, query)} className="btn btn-primary btn-lg mx-auto rounded-pill px-4">
          Okay!
        </Link>
      </div>
    )
  }
}
